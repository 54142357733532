<template>
  <ValidationProvider tag="div" class="column is-half" :rules="validationRules" v-slot="{ errors }">
    <div class="question-title">
      <span v-if="showQuestionCode">{{ question.code }}: </span><span v-html="question.question[lang]"></span>
      <p v-if="errors[0]" class="help is-danger">{{ errors[0] }}</p>
    </div>
    <div class="card">
      <div class="card-content">
        <b-field v-for="answer in question.answers" :key="answer.id">
          <b-checkbox
              v-model="selected"
              :native-value="answer.code"
              :disabled="loading || disabled.includes(answer.code)"
              @input.native="onInputNative(question.qid, answer, $event)"
          >
            <span v-html="answer.answer[lang]"></span>
          </b-checkbox>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>

import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'MultipleAnswer',
  mixins: [QuestionMixin],
  data () {
    return {
      selected: [],
      disabled: [],
    }
  },
  methods: {
    onInputNative (qid, answer, event) {
      const isChecked = event.target.checked

      const value = isChecked ? '1' : ''

      if (answer.options?.exclusive) {
        this.exclusive(qid, answer.code, isChecked)
      }
      const changes = { [this.questionKey(qid, null, answer.code)]: value }
      this.$emit('input', changes)
    },
    exclusive (qid, acode, isChecked) {
      if (isChecked) {
        this.disabled = this.question.answers
            .filter(answer => answer.code !== acode)
            .map(answer => answer.code)
        const previousSelected = this.selected.filter(answer => answer !== acode)
        this.selected = [acode]
        const changes = previousSelected
            .reduce((acc, acode) => ({ ...acc, [this.questionKey(qid, null, acode)]: '' }), {})
        this.$emit('input', changes)
      } else {
        this.disabled = []
      }
      this.isExclusive = isChecked
    },
    mapSelected () {
      return Object.keys(this.value)
          .filter(key => key.split('_')[0] === `${this.question.qid}` && this.value[key] === '1')
          .map(qid => qid.split('_')[2])
    }
  },
  created () {
    this.selected = this.mapSelected() || []
    const exclusiveAnswer = this.question.answers.find(answer => answer?.options?.exclusive && this.selected.includes(answer.code))
    if (exclusiveAnswer) {
      this.exclusive(this.question.qid, exclusiveAnswer.code, true)
    }
  }
}
</script>
